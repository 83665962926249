import httpRequest from '../request'

// 完成服务，将服务中改成待验收
export function finishedServe<T>(payload, cb?: () => void, errCb?: () => void) {
  return httpRequest.put<T>('seeyard/finish_service', payload, cb, errCb)
}
//报价并分配负责人
export function setQuoteDistribute<T>(
  payload,
  cb?: () => void,
  errCb?: () => void
) {
  return httpRequest.put<T>('seeyard/quote_and_distribute', payload, cb, errCb)
}
// 获取订单详细信息
export function getOrderDetail<T>(
  order_id,
  cb?: () => void,
  errCb?: () => void
) {
  return httpRequest.get<T>(`seeyard/order/${order_id}`, cb, errCb)
}
// 保存服务进度
export function addServiceProcess<T>(
  payload,
  cb?: () => void,
  errCb?: () => void
) {
  return httpRequest.post<T>('seeyard/add_progress', payload, cb, errCb)
}
// 余额充值
export function setRecharge<T>(
  payload,
  cb?: (res: any) => void,
  errCb?: () => void
) {
  return httpRequest.post<T>('seeyard/balance/recharge', payload, cb, errCb)
}
// 获取服务站简化列表
export function serviceStationList<T>(
  payload?,
  cb?: () => void,
  errCb?: () => void
) {
  return httpRequest.get<T>('seeyard/station_simple', payload, cb, errCb)
}
// 导出订单信息
export function downloadOrders<T>(
  payload,
  cb?: () => void,
  errCb?: () => void
) {
  return httpRequest.get<T>('seeyard/export', payload, cb, errCb)
}
// 批量导入
export function batchImport<T>(payload, cb?: () => void, errCb?: () => void) {
  return httpRequest.post<T>('seeyard/import', payload, cb, errCb)
}
// 获取成员信息
export function getMemberDetailInfo(params: {
  split_oid: number
  group_id: number
  uid: number
}) {
  return httpRequest.get(`seeyard/split_order/group/${params.group_id}`, params)
}

// 获取群聊详细信息
export function getSalesManList() {
  return httpRequest.get(`seeyard/admin/sales_man`)
}
// 获取服务站
export const getServiceStationsList = (obj?: any) => {
  return httpRequest.get('seeyard/index/station', obj)
}

// 订单操作接口
export function batchEditOrder<T>(
  payload,
  cb?: () => void,
  errCb?: () => void
) {
  return httpRequest.put<T>('seeyard/admin/order/batch', payload, cb, errCb)
}
